

import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddPriceTemplateModal from "@/views/apps/research/AddPriceTemplateModal.vue";
import AddPriceReadingModal from "@/components/modals/forms/AddPriceReadingModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import AddPriceAttributeModal from "@/components/modals/forms/AddPriceAttributeModal.vue";

import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";
import { Modal } from "bootstrap";
import UploadPriceModal from "@/components/modals/forms/UploadPriceModal.vue";
import { status_type } from "@/core/data/genericData";
import moment from "moment";

export default defineComponent({
  name: "apps-upload-listing",
  components: {
    ExportCustomerModal,
   // AddPriceTemplateModal, 
    //AddPriceReadingModal,
    //AddPriceAttributeModal,
        UploadPriceModal,
  },
   props: 
  {
      id: { required: true },
  },
  
  // methods:{
  //   onChange(){
  //     console.log('this.formDataValue.startDate')
  //     console.log(this.formDataValue.startDate)
  //     if(!this.formDataValue.startDate){
  //       console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh")
  //       this.formDataValue.startDate = ''
  //     }
  //   }
  // },
  
  setup(props) {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);    
    const loadingData = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const searchTearm = ref('');
    const viewDataModalRef = ref<null | HTMLElement>(null);
    var formDataViewValue = ref(
      { valuetbl2 : ""});
    var formDataValue = ref(
      { 
        cust_select: "", 
        startDate: "", 
        product_select: ""
        }
      );
    const status_list_data = status_type;
    const product_attr_modal = (price_template_id) => {
      const modal = new Modal(document.getElementById("kt_price_modal_attribute"));
      modal.show();
      (document.querySelector('#price_template') as HTMLInputElement).value = price_template_id;
    }

    interface Grade {
        price_record_id : number,
        product_id : number,
        product_name: string,
        business_name: string,
        business_city : string,
        business_district : string,
        business_state : string,
        business_pincode : number,
        business_nature : number,
        product_type : string,
        contact_person_name : string,
        contact_person_no : number,
        contact_person_email : string,
        product_brand_name : string,
        product_base_dimension : string,
        product_base_grade : string,
        booking_status : string,
        price_publish_frequency : string,
        origin_location : string,
        incoterm : string,
        morning_price : number,
        afternoon_price : number,
        evening_price : number,
        price_collection_date : string,
        remark : string,
    }

    const price_modal = (price_template_id) => {
      const modal = new Modal(document.getElementById("kt_price_modal"));
      modal.show();
      (document.querySelector('#price_template_id') as HTMLInputElement).value = price_template_id;
    }
    
    var paginationData = ref({})
    
    paginationData.value = {      
         start : 0,
          end : 0,
          total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1],
        page_select: 1
    }

    const refreshData =() => {      
      paginationData.value['activePage']  = 1;   
      paginationData.value['page_select'] = 1;  
      getList()
    }

    const changePageChange = (page) => {      
      paginationData.value['activePage']  = page;      
      getList()
       if(paginationData.value['totPage'] == page){
      paginationData.value['page_select'] = page
      }
      if(paginationData.value['activePage'] == 1){
      paginationData.value['page_select'] = page
      }
      
    }

    const product_type = ref([]);
    const setProductdata = async () => {
      loading.value = true;
      try {
        await store
          .dispatch(Actions.CUST_PRODUCT_PRICE_DATA)
          .then(({ data }) => {
            product_type.value = data;
            loading.value = false;
            console.log("DROPDOWN")
            console.log(product_type.value)
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    const startDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        const endDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        const startDate1 = ref();
        startDate1.value = [startDate , endDate]
        console.log( startDate1.value[0]) 
        formDataValue.value.startDate = startDate1.value
        var tableData = ref<Array<Grade>>([]);
      
     const getList = async () => {
       loadingData.value = true;
      var start_data = ""
        var end_date = ""
      if(!formDataValue.value['startDate']){
          start_data = ""
          end_date = ""
      }
      else
      {
        start_data = moment(formDataValue.value.startDate[0]).format("YYYY-MM-DD")
        end_date = moment(formDataValue.value.startDate[1]).format("YYYY-MM-DD")
      }
      console.log( formDataViewValue.value['startDate']) 
      try {

        var values = { 
          "page"  : parseInt(paginationData.value['activePage']) || parseInt(paginationData.value['page_select']), 
          "records_per_page" : parseInt(paginationData.value['perPage']) ,
          "start_date": start_data,
          "end_date": end_date,
          "product_id":formDataValue.value.product_select 
          }

        await store.dispatch(Actions.CUST_LIST_PRICE_DATA, values).then(({ data }) => {

          console.log(data);
          
          tableData.value = ([]);
          if(data != 0){
          // set pagination
          paginationData.value['total'] = data.total_records;        
          paginationData.value['start'] = data.records_from
          paginationData.value['end'] = data.records_upto
          paginationData.value['activePage'] = data.page;
          paginationData.value['totPage']  = data.total_pages
          paginationData.value['pageRange'] = []
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          //endpagination
          console.log("paginationData.value")
          console.log(paginationData.value)
          var resultsM = ref<Array<Grade>>([])
          
          for (let j = 0; j < data.result_list.length; j++) {

            resultsM.value = Array({
              price_record_id : data.result_list[j]['price_record_id'],
              product_id: data.result_list[j]['product_id'],
              product_name: data.result_list[j]['product_name'],
              business_name: data.result_list[j]['business_name'],
              business_city: data.result_list[j]['business_city'],
              business_district: data.result_list[j]['business_district'],
              business_state: data.result_list[j]['business_state'],
              business_pincode: data.result_list[j]['business_pincode'],
              business_nature : data.result_list[j]['business_nature'],
              product_type : data.result_list[j]['product_type'],
              contact_person_name : data.result_list[j]['contact_person_name'],
              contact_person_no : data.result_list[j]['contact_person_no'],
              contact_person_email : data.result_list[j]['contact_person_email'],
              product_brand_name : data.result_list[j]['product_brand_name'],
              product_base_dimension : data.result_list[j]['product_base_dimension'],
              product_base_grade: data.result_list[j]['product_base_grade'],
              booking_status: data.result_list[j]['booking_status'],
              price_publish_frequency: data.result_list[j]['price_publish_frequency'],
              origin_location: data.result_list[j]['origin_location'],
              incoterm: data.result_list[j]['incoterm'],
              morning_price: data.result_list[j]['morning_price'],
              afternoon_price: data.result_list[j]['afternoon_price'],
              evening_price: data.result_list[j]['evening_price'],
              price_collection_date: data.result_list[j]['price_collection_date'],
              remark: data.result_list[j]['remark'],

            })

            tableData.value.splice(j, resultsM.value.length, ...resultsM.value);            
          }

          loadingData.value = false;
          }
          if(data == 0){
            loadingData.value = false;
            console.log("EMPTY DATA")
            // set pagination
          paginationData.value['total'] = 0;        
          paginationData.value['start'] = 0
          paginationData.value['end'] = 0
          paginationData.value['activePage'] = 1;
          paginationData.value['totPage']  = 1
          paginationData.value['pageRange'] = [1]
          for (let index =  data.bar_range_start; index < data.bar_range_end; index++) {
            paginationData.value['pageRange'].push(index)
          }
          // paginationData.value['page_select'] = 0
          //endpagination
          }
        }).catch(({ response }) => {

          tableData.value = [];
          loadingData.value = false;

       });

      } catch (e) {
        console.log(e);
      }
    //  }
      
    };

     
      onUpdated(() => {          
        // console.log("aaap");
        // console.log(list_data);
      });

      

      const initCustomers = ref<Array<Grade>>([]);                      
      
      onMounted( async () => {
        await getList()
        
        setCurrentPageBreadcrumbs("Upload Price Data", []);
        initCustomers.value.splice(0, tableData.value.length, ...tableData.value);        
        console.log(tableData.value.length);
      });  

      const deleteFewCustomers = () => {
        checkedCompany.value.forEach((item) => {
          deleteCustomer(item);
        });
        checkedCompany.value.length = 0;
      };

      const deleteCustomer = (id) => {
        for (let i = 0; i < tableData.value.length; i++) {
          if (tableData.value[i].price_record_id === id) {
            tableData.value.splice(i, 1);
          }
        }
      };

      const search = ref<string>("");
       const searchItems = () => {
          searchTearm.value = search.value;
          refreshData();
          getList();
      };

      const searchingFunc = (obj, value): boolean => {        
        for (let key in obj) {          
          if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {            
            if (obj[key].indexOf(value) != -1) {
              
              return true;
            }
          }
        }
        return false;
      };       
      
      interface ViewCategories {
        price_record_id,
        product_id,
        product_name,
        business_name,
        business_city,
        business_district,
        business_state,
        business_pincode,
        business_nature,
        product_type,
        contact_person_name,
        contact_person_no,
        contact_person_email,
        product_brand_name,
        product_base_dimension,
        product_base_grade,
        booking_status,
        price_publish_frequency,
        origin_location,
        incoterm,
        morning_price,
        afternoon_price,
        evening_price,
        price_collection_date,
        remark,
        price_upload_time
    };    
    
    var resultsView = ref<ViewCategories>();
  
    resultsView.value = {
      price_record_id: "",
      product_id: "",
      product_name: "",
      business_name: "",
      business_city: "",
      business_district: "",
      business_state:"",
      business_pincode: "",
      business_nature: "",
      product_type: "",
      contact_person_name: "",
      contact_person_no: "",
      contact_person_email: "",
      product_brand_name: "",
      product_base_dimension: "",
      product_base_grade: "",
      booking_status: "",
      price_publish_frequency: "",
      origin_location: "",
      incoterm: "",
      morning_price: "",
      afternoon_price: "",
      evening_price: "",
      price_collection_date: "",
      remark: "",
      price_upload_time: "",
    }

    const view_modal = (price_record_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_product_view_attr_setting_value")
      );
      modal.show();
      (document.querySelector("#value_tbl2") as HTMLInputElement).value =
        price_record_id;
      getGradeDetails();
    };

    var tableData2 = ref<Array<ViewCategories>>([]);
    const getGradeDetails = async () => {
     
      try {

      var values = { "price_record_id"  :  (document.querySelector("#value_tbl2") as HTMLInputElement).value}

      await store.dispatch(Actions.CUST_VIEW_PRICE_DATA, values).then(({ data }) => {
       tableData2.value = [];
        
      // for (let j = 0; j < data.length; j++) {
        resultsView.value = {
          price_record_id : data.price_record_id,
          product_id : data.product_id,
          product_name : data.product_name,
          business_name : data.business_name,
          business_city : data.business_city,
          business_district : data.business_district,
          business_state : data.business_state,
          business_pincode : data.business_pincode,
          business_nature : data.business_nature,
          product_type : data.product_type,
          contact_person_name: data.contact_person_name,
          contact_person_no: data.contact_person_no,
          contact_person_email: data.contact_person_email,
          product_brand_name: data.product_brand_name,
          product_base_dimension: data.product_base_dimension,
          product_base_grade: data.product_base_grade,
          booking_status: data.booking_status,
          price_publish_frequency: data.price_publish_frequency,
          origin_location: data.origin_location,
          incoterm: data.incoterm,
          morning_price: data.morning_price,
          afternoon_price: data.afternoon_price,
          evening_price: data.evening_price,
          price_collection_date: data.price_collection_date,
          remark: data.remark,
          price_upload_time: data.price_upload_time,
        }
          //tableData2.value.splice(j, resultsView.value.length, ...resultsView.value);

      // }
      console.log(resultsView)

      })
      .catch(({ response }) => {
        console.log(response);
      });
        
      } catch (e) {
        console.log(e);
      }
    };     
    
       
    return {
      tableData,
      tableData2,  
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      price_modal,
      product_attr_modal,
      count,      
      getList,   
      //refresh,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      getGradeDetails,
      view_modal,
      viewDataModalRef,
      formDataValue,
      formDataViewValue,
      resultsView,
      status_list_data,
      setProductdata,
      product_type,
      loading,
    };  

  }  

});
